@use "../utils" as *;

/*----------------------------------------*/
/*  08. cursor CSS START
/*----------------------------------------*/

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  margin-left: -12.5px;
  margin-top: -12.5px;
  width: 25px;
  height: 25px;
  opacity: 0.6;
  z-index: 999;
  background-color: var(--cf-theme-primary);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  @media #{$sm,$xs} {
    display: none;
  }
}
.cursor.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: var(--cf-theme-primary);
  opacity: 0.3;
}
