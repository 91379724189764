@use "../utils" as *;

/*----------------------------------------*/
/*  04. Modal Css Start
/*----------------------------------------*/

.axo-popup .mfp-content {
  background: var(--cf-theme-white);
  width: 500px;
  margin: 0 auto;
  border-radius: 20px;
  @media #{$xs} {
    width: 300px;
  }
  .mfp-close {
    background: var(--cf-theme-white);
    color: var(--cf-theme-dark);
    border-radius: 50%;
    margin: 10px;
  }
  .content {
    .price {
      color: var(--cf-theme-dark);
      font-size: 20px;
      margin-top: 0 !important;
    }
    .img {
      margin-top: -2px;
      img {
        width: 100%;
        border-radius: 20px 20px 0 0;
      }
    }
    .des {
      padding: 20px 30px 20px;
      span {
        color: var(--cf-theme-primary);
        font-size: 18px;
      }
      h4 {
        color: var(--cf-theme-dark);
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 1.3;
        @media #{$xs} {
          font-size: 18px;
        }
      }
      .category {
        margin: 0;
        margin-bottom: 5px;
      }
      p {
        margin-top: 20px;
      }
      .d-flex {
        display: flex;
        @media #{$xs} {
          display: block;
        }
      }
      ul {
        li {
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          @media #{$xs} {
            margin-right: 0;
            margin-bottom: 5px;
          }
          a {
            color: var(--cf-theme-primary);
            font-size: 14px;
          }
          &::before {
            content: "";
            height: 7px;
            width: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

div#about-popup {
  padding: 30px;
  .about-image img {
    border-radius: 20px;
  }
  h3 {
    color: var(--cf-theme-dark);
    font-size: 36px;
  }
  .subtitle {
    font-size: 20px;
    color: var(--cf-theme-primary);
    height: 50px;
  }
  .typed-cursor {
    display: none;
  }
  ul.about-details {
    margin: 50px 0;
  }

  ul.skills {
    margin-bottom: 50px;
  }
  ul.about-details {
    border: 2px dashed #f2f2f2;
    border-radius: 20px;
    span {
      color: var(--cf-theme-dark);
    }
    li {
      padding: 17px 30px;
      border-bottom: 2px dashed #f2f2f2;
      &:last-child {
        border: 0;
      }
    }
  }
  .skills {
    p {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 20px;
    }
    .skill {
      height: 8px;
      width: 100%;
      background: #f2f2f2;
      border-radius: 20px;
      position: relative;
      &::after {
        content: "";
        height: 100%;
        background: var(--cf-theme-primary);
        border-radius: 20px;
        position: absolute;
      }
    }
    @for $i from 1 through 100 {
      .skill.p#{$i} {
        &::after {
          width: $i * 1%;
        }
      }
    }
  }
}
