@use "../utils" as *;

/*----------------------------------------*/
/*  16. blog CSS START
/*----------------------------------------*/
#blog {
  margin:100px 0px;
  .blog-items {
    margin-top: 60px;
  }
  .blog-item {
    // background: #f2f2f2;
    border-radius: 20px;
    margin-bottom: 30px;
    box-shadow: $shadow;
    span {
      color: var(--cf-theme-primary);
    }
    .blog-image-container {
      overflow: hidden;
      border-radius: 20px 20px 0 0;
    }
    img {
      width: 100%;
      border-radius: 20px 20px 0 0;
      transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
      vertical-align: middle;
      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
    .blog-content {
      padding: 30px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-top: 0;
      }
    }
    h4 {
      color: var(--cf-theme-dark);
      margin: 10px 0;
    }
  }
}
