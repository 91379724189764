@use "../utils" as *;

/*----------------------------------------*/
/*  14. testimonials CSS START
/*----------------------------------------*/

#testimonials {
  .testimonials-top {
    @include flexbox(center, space-between);
    .arrows {
      @include flexbox(center, space-between);
    }
    .arrow-left {
      margin-right: 10px;
    }
    .arrow {
      width: 60px;
      height: 60px;
      overflow: hidden;
      background-color: #f2f2f2;
      border-radius: 50px;
      cursor: pointer;
      font-size: 20px;
      display: inline-block;
      @include flexbox(center, center);
      @include transition(0.3s);
      &.active {
        background: var(--cf-theme-primary);
        color: var(--cf-theme-white);
      }
      &:hover {
        background: var(--cf-theme-primary);
        color: var(--cf-theme-white);
      }
    }
  }
  .testimonial-sliders {
    margin-top: 50px;
    box-shadow: $shadow;
    border-radius: 20px;
  }
  .slider-item {
    // background: #f2f2f2;
    border-radius: 20px;
    display: flex;
    box-shadow: 0px 20px 35px 10px rgb(4 9 30 / 7%);
    .author-info {
      @include flexbox(center, start);
      margin-top: 40px;
      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
    h3 {
      color: var(--cf-theme-dark);
    }
    .complay-name {
      font-size: 20px;
    }
    p {
      font-style: italic;
    }
    .slider-item-content {
      padding: 48px 36px 30px;
    }
  }
}
