.card-img-news{
    width: 300px;
    border-radius: 10px;
}
.card-news-h1 {
    font-size: 18px;
    text-transform: capitalize;
    padding: 9px 0px;
    margin: 0;
    font-family: "Lato", sans-serif;
}
.card-news-p1{

}
.new-d1{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border-radius: 10px;
}
.new-d3 {
    display: flex;
    flex-direction: column;
}
.read-more-span{
    cursor: pointer;
    color: #26959b;
}
.news-d2{
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    justify-content: center;
}

.new-d-img {
    width: 100%;
    height: 600px;
    object-fit: contain;
}
.new-details-card {
    display: flex;
    flex-direction: column;
  
    width: 800px;
    border-radius: 10px;
    gap: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 50px;
}
.new-d-h1{
    font-family: "Lato", sans-serif;
    font-size: 18px;

}
.news-d-p {
    font-size: 18px;
    /* line-height: 30px; */
    color: black;
    /* margin: 0; */
    margin-bottom: 5px;
}
.newz-card-det1 {
    display: flex;
    justify-content: center;
    padding: 150px 0px;
}

.new-d-h2 {
    font-family: "Lato", sans-serif;
    font-size: 14px;
}


@media only screen and (max-width: 600px) {

    .new-d-img {
        width: 100%;
        height: 416px;
        object-fit: contain;
    }
    .news-d-p {
        font-size: 13px;
        /* line-height: 30px; */
        color: black;
        /* margin: 0; */
        margin-bottom: 5px;
    }


    .newz-card-det1 {
        display: flex;
        justify-content: center;
        padding: 82px 0px;
    }

    .new-d-h1 {
        font-family: "Lato", sans-serif;
        font-size: 14px;
    }
    .new-details-card {
width: unset;
padding: 20px;
    }
}

.news-h1d{
    text-align: center;
    padding: 41px 0px;
    color: #26959b;
}