/*----------------------------------------*/
/*  07. pogress CSS START
/*----------------------------------------*/

.progress {
  background: transparent;
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  // icon
  &::after {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    content: "\f176";
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: var(--cf-theme-dark);
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  // circle container hover
  &:hover {
    // icon hover
    &::after {
      position: absolute;
      font-family: "Font Awesome 5 Pro";
      content: "\f176";
      color: var(--cf-theme-dark);
      transition: all 200ms linear;
    }
  }

  svg {
    path {
      fill: none;
    }
    &.progress-svg {
      path {
        stroke: var(--cf-theme-dark);
        stroke-width: 4;
        box-sizing: border-box;
        transition: all 200ms linear;
      }
    }
  }
}
