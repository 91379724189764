* {
  margin: 0;
  padding: 0;
}
.portfolio-items {
  display: flex;
  flex-wrap: wrap;
}
.sticky {
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #212025;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.l1 {
  cursor: pointer;
}
.flex-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.flex-footer2 {
  display: flex;
  gap: 10px;
}
.lines-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.btn-apple {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 5px;
  border-radius: 15px;
  background-color: black;
  color: white;
}
.btns-home {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.btn-android {
  display: flex;
  padding: 10px 19px;
  gap: 5px;
  border-radius: 15px;
  background-color: black;
  align-items: center;
  color: white;
}

.d-f-home1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 100px 0px;
}
.img-screenshot {
  height: 480px;
  width: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.spa-f {
  font-weight: 300;
  font-size: 14px;
}
.f-wrap1 {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .d-f-home1 {
    padding: unset !important;
  }
  .header-container {
    padding: 10px 0px !important;
  }
}

@media only screen and (max-width: 1358px) {
  .d-f-home1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px;
    flex-wrap: wrap;
  }
  .lef-h-content-1 {
    width: unset !important;
    padding: 20px;
  }
}
.rotate-dd {
  display: flex;
  gap: 10px;
}
.scale-img1 {
  transform: scale(0.9);
}
.scale-img2 {
  transform: scale(0.8);
}
.lef-h-content-1 {
  width: 500px;
}

@media only screen and (max-width: 780px) {
  .img-screenshot {
    height: 370px;
    width: 193px;
    /* object-fit: cover; */
    border-radius: 15px;
  }
}

@media only screen and (max-width: 590px) {
  .img-screenshot {
    height: 300px;
    width: 158px;
    /* object-fit: cover; */
    border-radius: 15px;
  }
}
@media only screen and (max-width: 490px) {
  .img-screenshot {
    height: 250px;
    width: 133px;
    /* object-fit: cover; */
    border-radius: 15px;
  }
}
@media only screen and (max-width: 416px) {
  .img-screenshot {
    height: 200px;
    width: 110px;
    /* object-fit: cover; */
    border-radius: 15px;
  }
}
@media only screen and (max-width: 350px) {
  .rotate-dd {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .scale-img1 {
    transform: unset;
  }
  .scale-img2 {
    transform: unset;
  }
}

.swiper-button-prev {
  /* background: #fff !important; */
  border-radius: 50% !important;
  color: #000 !important;
  font-size: 25px !important;
  height: 60px !important;

  width: 60px !important;
}
.swiper-button-next {
  /* background: #fff !important; */
  border-radius: 50% !important;
  color: #000 !important;
  font-size: 25px !important;
  height: 60px !important;

  width: 60px !important;
}
#Home {
  background-color: #212025 !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 25px !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 25px !important;
}
.short-bio {
  color: white !important;
}

.project > {
  color: white !important;
}
.clients > p {
  color: white !important;
}
.experence > p {
  color: white !important;
}
.whats-app-icon {
  bottom: 7.6vw;
  /* left: 635px; */
  left: 33vw;
  position: absolute;
}
.carou-ss1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-whats-app{
  width: 50px;
}

@media only screen and (max-width: 1200px) {


  .img-whats-app{
    width: 30px;
  }
  
}
@media only screen and (max-width: 670px) {


  .img-whats-app{
    width: 20px;
  }
  .whats-app-icon {
  
    position: absolute;
}
.client-h1{

  text-align: center;
  padding: 41px 0px;
  color: #26959b;
  font-size: 25px !important;
}
  
}
.logo-nav-b{
  width: 50px;
}

@media only screen and (max-width: 450px) {

.logo-nav-b{
  width: 25px !important;
}
}
.our-h1{
  color: #26959b;
}
.js-our {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.blog-content{
  text-align: center;
}
.card-our{
  display: flex;
    flex-direction: column;
    align-items: center;
}

.whats-app-img-sc {
  border-radius: 100px;
  width: 50px;
}
/* .scroll-to-top2 {
  border: 1px solid transparent;
  border-radius: 50%;
  bottom: 20px;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 16px;
  position: fixed;
  right: 20px;
  z-index: 50;
} */

.scroll-to-top2{

  z-index: 50;
  position: fixed;
  right: 20px;
  bottom: 77px;
}
#portfolio .portfolio-container .item {
  overflow: hidden;
  margin: 10px;
  border-radius: 20px;
  height: 500px;
  width: calc(33% - 20px);
}

.c-pointer {
  cursor: pointer;
  width: 100%;
  height: 500px;
}

#testimonials{
padding: 50px 0px ;
}


.client-h1{

  text-align: center;
  padding: 41px 0px;
  color: #26959b;
  font-size: 45px;
}

#testimonials .slider-item .slider-item-content {
  padding: 48px 36px 30px;
  height: 350px;
  overflow-y: scroll;
  overflow: auto;
}


* {
  -webkit-user-select: none!important;
  user-select: none!important;
}