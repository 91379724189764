// font url

$font-url: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

// font family
$font-family: (
  ff: (
    body: "'Rubik', sans-serif",
    heading: "'Poppins', sans-serif",
    subheading: "'Poppins', sans-serif",
    p: "'Rubik', sans-serif",
    fontawesome: '"Font Awesome 6 Pro"',
  ),
);

// font weight
$font-scale: (
  fw: (
    normal: normal,
    elight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    sbold: 600,
    bold: 700,
    ebold: 800,
    black: 900,
  ),
);

// font size
$font-size: (
  fs: (
    body: 18px,
    p: 18px,
    h1: 72px,
    h2: 48px,
    h3: 24px,
    h4: 22px,
    h5: 18px,
    h6: 14px,
  ),
);
