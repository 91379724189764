@use "../utils" as *;

/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

01. Theme Default Css
02. BS Grid css
03. buttons css
04. modal css
05. section-title css
06. preloader css
07. pogress css
08. cursor css
09. header css
10. hero css
11. service css
12. about css
13. portfolio css
14. testimonials css
15. client css
16. blog css
17. contact css
18. footer css


**********************************************/

/*----------------------------------------*/
/*  01. TTheme Default Css Start
/*----------------------------------------*/
@import url($font-url);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--cf-ff-body);
  font-size: var(--cf-fs-body);
  font-weight: normal;
  color: var(--cf-text-body);
  line-height: 1.5;
}

a {
  text-decoration: none;
}

h1,
h2,
h5,
h6 {
  font-family: var(--cf-ff-heading);
  color: var(--cf-heading-primary);
  margin-top: 0px;
  font-weight: var(--cf-fw-sbold);
  line-height: 1.2;
  // @include transition(0.3s);
}

h4,
h3 {
  font-weight: var(--cf-fw-medium);
}

h1 {
  font-size: var(--cf-fs-h1);
  @media #{$md} {
    font-size: 60px;
    line-height: 1.3;
  }
  @media #{$xs,$sm} {
    font-size: 50px;
    line-height: 1.3;
  }
}

h2 {
  font-size: var(--cf-fs-h2);
  @media #{$md} {
    font-size: 36px;
    line-height: 1.3;
  }
  @media #{$xs} {
    font-size: 26px;
    line-height: 1.3;
  }
}

h3 {
  font-size: var(--cf-fs-h3);
  @media #{$md,$sm,$xs} {
    font-size: 22px;
  }
}

h4 {
  font-size: var(--cf-fs-h4);
}

h5 {
  font-size: var(--cf-fs-h5);
}

h6 {
  font-size: var(--cf-fs-h6);
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-family: var(--cf-ff-p);
  font-size: var(--cf-fs-p);
  font-weight: var(--cf-fw-normal);
  color: var(--cf-text-body);
  margin-bottom: 15px;
  line-height: 27px;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

input,
textarea {
  outline: none;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}
::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}
::selection {
  background: var(--cf-theme-dark);
  color: var(--cf-theme-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}
*::placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
img {
  max-width: 100%;
  height: auto;
}
.d-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
.social-icons {
  a {
    margin-right: 10px;
  }
}
.c-pointer {
  cursor: pointer;
}

$align-items: (center, end, start);

@each $align in $align-items {
  .align-#{$align} {
    align-items: $align;
  }
}

$justify-content: (center, end, start, space-between, space-around);

@each $align in $justify-content {
  .content-#{$align} {
    justify-content: $align;
  }
}

$position: (absolute, fixed, relative, sticky, unset);
@each $align in $position {
  .position-#{$align} {
    position: $align;
  }
}

@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .text-#{$shade} {
      color: #{$value};
    }
    .bg-#{$shade} {
      background-color: #{$value};
    }
  }
}

@each $fz, $shades in $font-size {
  @each $shade, $value in $shades {
    .font-#{$value} {
      font-size: $value;
    }
  }
}
