/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

01. Theme Default Css
02. BS Grid css
03. buttons css
04. modal css
05. section-title css
06. preloader css
07. pogress css
08. cursor css
09. header css
10. hero css
11. service css
12. about css
13. portfolio css
14. testimonials css
15. client css
16. blog css
17. contact css
18. footer css


**********************************************/
/*----------------------------------------*/
/*  01. TTheme Default Css Start
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  /** @font family declaration */
  --cf-ff-body: 'Rubik', sans-serif;
  --cf-ff-heading: 'Poppins', sans-serif;
  --cf-ff-subheading: 'Poppins', sans-serif;
  --cf-ff-p: 'Rubik', sans-serif;
  --cf-ff-fontawesome: "Font Awesome 6 Pro";
  /** @color declaration */
  --cf-theme-primary: #26959B;
  --cf-theme-secondary: #f05223;
  --cf-theme-purple: #150b2f;
  --cf-theme-white: #ffffff;
  --cf-theme-dark: #212025;
  --cf-theme-hero-bg: #212025;
  --cf-grey-dark: #212121;
  --cf-grey-light: #a6a6a6;
  --cf-text-body: #9f9f9f;
  --cf-text-1: #000;
  --cf-border-1: #f7f7f7;
  /** @font weight declaration */
  --cf-fw-normal: normal;
  --cf-fw-elight: 200;
  --cf-fw-light: 300;
  --cf-fw-regular: 400;
  --cf-fw-medium: 500;
  --cf-fw-sbold: 600;
  --cf-fw-bold: 700;
  --cf-fw-ebold: 800;
  --cf-fw-black: 900;
  /** @font size declaration */
  --cf-fs-body: 18px;
  --cf-fs-p: 18px;
  --cf-fs-h1: 72px;
  --cf-fs-h2: 48px;
  --cf-fs-h3: 24px;
  --cf-fs-h4: 22px;
  --cf-fs-h5: 18px;
  --cf-fs-h6: 14px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--cf-ff-body);
  font-size: var(--cf-fs-body);
  font-weight: normal;
  color: var(--cf-text-body);
  line-height: 1.5;
}

a {
  text-decoration: none;
}

h1,
h2,
h5,
h6 {
  font-family: var(--cf-ff-heading);
  color: var(--cf-heading-primary);
  margin-top: 0px;
  font-weight: var(--cf-fw-sbold);
  line-height: 1.2;
}

h4,
h3 {
  font-weight: var(--cf-fw-medium);
}

h1 {
  font-size: var(--cf-fs-h1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 60px;
    line-height: 1.3;
  }
}
@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  h1 {
    font-size: 50px;
    line-height: 1.3;
  }
}

h2 {
  font-size: var(--cf-fs-h2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
    line-height: 1.3;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 26px;
    line-height: 1.3;
  }
}

h3 {
  font-size: var(--cf-fs-h3);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  h3 {
    font-size: 22px;
  }
}

h4 {
  font-size: var(--cf-fs-h4);
}

h5 {
  font-size: var(--cf-fs-h5);
}

h6 {
  font-size: var(--cf-fs-h6);
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-family: var(--cf-ff-p);
  font-size: var(--cf-fs-p);
  font-weight: var(--cf-fw-normal);
  color: var(--cf-text-body);
  margin-bottom: 15px;
  line-height: 27px;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
textarea {
  outline: none;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}

::selection {
  background: var(--cf-theme-dark);
  color: var(--cf-theme-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::-webkit-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*:-ms-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::-ms-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
img {
  max-width: 100%;
  height: auto;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.w-100 {
  width: 100%;
}

.social-icons a {
  margin-right: 10px;
}

.c-pointer {
  cursor: pointer;
}

.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}

.align-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.content-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.content-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.content-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.content-space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.content-space-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.position-unset {
  position: unset;
}

.text-primary {
  color: #26959B;
}

.bg-primary {
  background-color: #26959B;
}

.text-secondary {
  color: #f05223;
}

.bg-secondary {
  background-color: #f05223;
}

.text-purple {
  color: #150b2f;
}

.bg-purple {
  background-color: #150b2f;
}

.text-white {
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.text-dark {
  color: #212025;
}

.bg-dark {
  background-color: #212025;
}

.text-hero-bg {
  color: #212025;
}

.bg-hero-bg {
  background-color: #212025;
}

.text-dark {
  color: #212121;
}

.bg-dark {
  background-color: #212121;
}

.text-light {
  color: #a6a6a6;
}

.bg-light {
  background-color: #a6a6a6;
}

.text-body {
  color: #9f9f9f;
}

.bg-body {
  background-color: #9f9f9f;
}

.text-1 {
  color: #000;
}

.bg-1 {
  background-color: #000;
}

.text-1 {
  color: #f7f7f7;
}

.bg-1 {
  background-color: #f7f7f7;
}

.font-18px {
  font-size: 18px;
}

.font-18px {
  font-size: 18px;
}

.font-72px {
  font-size: 72px;
}

.font-48px {
  font-size: 48px;
}

.font-24px {
  font-size: 24px;
}

.font-22px {
  font-size: 22px;
}

.font-18px {
  font-size: 18px;
}

.font-14px {
  font-size: 14px;
}

/*----------------------------------------*/
/*  02. BS Grid Css Start
/*----------------------------------------*/
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1360px;
    max-width: 100%;
  }
}
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 768px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 992px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 1200px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* 00. ========= sticky animation ========== */
@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
/*----------------------------------------*/
/*  03. Button Css Start
/*----------------------------------------*/
.cf-btn {
  background: var(--cf-theme-primary);
  border: 1px solid var(--cf-theme-primary);
  border-radius: 55px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--cf-theme-white);
  padding: 20px 43px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: var(--cf-theme-white);
  display: inline-block;
}
.cf-btn:hover {
  color: var(--cf-theme-white);
  -webkit-transform: translateY(-0.15rem);
          transform: translateY(-0.15rem);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
          box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
}

/*----------------------------------------*/
/*  04. Modal Css Start
/*----------------------------------------*/
.axo-popup .mfp-content {
  background: var(--cf-theme-white);
  width: 500px;
  margin: 0 auto;
  border-radius: 20px;
}
@media (max-width: 575px) {
  .axo-popup .mfp-content {
    width: 300px;
  }
}
.axo-popup .mfp-content .mfp-close {
  background: var(--cf-theme-white);
  color: var(--cf-theme-dark);
  border-radius: 50%;
  margin: 10px;
}
.axo-popup .mfp-content .content .price {
  color: var(--cf-theme-dark);
  font-size: 20px;
  margin-top: 0 !important;
}
.axo-popup .mfp-content .content .img {
  margin-top: -2px;
}
.axo-popup .mfp-content .content .img img {
  width: 100%;
  border-radius: 20px 20px 0 0;
}
.axo-popup .mfp-content .content .des {
  padding: 20px 30px 20px;
}
.axo-popup .mfp-content .content .des span {
  color: var(--cf-theme-primary);
  font-size: 18px;
}
.axo-popup .mfp-content .content .des h4 {
  color: var(--cf-theme-dark);
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.3;
}
@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des h4 {
    font-size: 18px;
  }
}
.axo-popup .mfp-content .content .des .category {
  margin: 0;
  margin-bottom: 5px;
}
.axo-popup .mfp-content .content .des p {
  margin-top: 20px;
}
.axo-popup .mfp-content .content .des .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des .d-flex {
    display: block;
  }
}
.axo-popup .mfp-content .content .des ul li {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
}
@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des ul li {
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.axo-popup .mfp-content .content .des ul li a {
  color: var(--cf-theme-primary);
  font-size: 14px;
}
.axo-popup .mfp-content .content .des ul li::before {
  content: "";
  height: 7px;
  width: 7px;
  display: block;
  border-radius: 50%;
  margin-right: 5px;
}

div#about-popup {
  padding: 30px;
}
div#about-popup .about-image img {
  border-radius: 20px;
}
div#about-popup h3 {
  color: var(--cf-theme-dark);
  font-size: 36px;
}
div#about-popup .subtitle {
  font-size: 20px;
  color: var(--cf-theme-primary);
  height: 50px;
}
div#about-popup .typed-cursor {
  display: none;
}
div#about-popup ul.about-details {
  margin: 50px 0;
}
div#about-popup ul.skills {
  margin-bottom: 50px;
}
div#about-popup ul.about-details {
  border: 2px dashed #f2f2f2;
  border-radius: 20px;
}
div#about-popup ul.about-details span {
  color: var(--cf-theme-dark);
}
div#about-popup ul.about-details li {
  padding: 17px 30px;
  border-bottom: 2px dashed #f2f2f2;
}
div#about-popup ul.about-details li:last-child {
  border: 0;
}
div#about-popup .skills p {
  margin-bottom: 10px;
}
div#about-popup .skills li {
  margin-bottom: 20px;
}
div#about-popup .skills .skill {
  height: 8px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 20px;
  position: relative;
}
div#about-popup .skills .skill::after {
  content: "";
  height: 100%;
  background: var(--cf-theme-primary);
  border-radius: 20px;
  position: absolute;
}
div#about-popup .skills .skill.p1::after {
  width: 1%;
}
div#about-popup .skills .skill.p2::after {
  width: 2%;
}
div#about-popup .skills .skill.p3::after {
  width: 3%;
}
div#about-popup .skills .skill.p4::after {
  width: 4%;
}
div#about-popup .skills .skill.p5::after {
  width: 5%;
}
div#about-popup .skills .skill.p6::after {
  width: 6%;
}
div#about-popup .skills .skill.p7::after {
  width: 7%;
}
div#about-popup .skills .skill.p8::after {
  width: 8%;
}
div#about-popup .skills .skill.p9::after {
  width: 9%;
}
div#about-popup .skills .skill.p10::after {
  width: 10%;
}
div#about-popup .skills .skill.p11::after {
  width: 11%;
}
div#about-popup .skills .skill.p12::after {
  width: 12%;
}
div#about-popup .skills .skill.p13::after {
  width: 13%;
}
div#about-popup .skills .skill.p14::after {
  width: 14%;
}
div#about-popup .skills .skill.p15::after {
  width: 15%;
}
div#about-popup .skills .skill.p16::after {
  width: 16%;
}
div#about-popup .skills .skill.p17::after {
  width: 17%;
}
div#about-popup .skills .skill.p18::after {
  width: 18%;
}
div#about-popup .skills .skill.p19::after {
  width: 19%;
}
div#about-popup .skills .skill.p20::after {
  width: 20%;
}
div#about-popup .skills .skill.p21::after {
  width: 21%;
}
div#about-popup .skills .skill.p22::after {
  width: 22%;
}
div#about-popup .skills .skill.p23::after {
  width: 23%;
}
div#about-popup .skills .skill.p24::after {
  width: 24%;
}
div#about-popup .skills .skill.p25::after {
  width: 25%;
}
div#about-popup .skills .skill.p26::after {
  width: 26%;
}
div#about-popup .skills .skill.p27::after {
  width: 27%;
}
div#about-popup .skills .skill.p28::after {
  width: 28%;
}
div#about-popup .skills .skill.p29::after {
  width: 29%;
}
div#about-popup .skills .skill.p30::after {
  width: 30%;
}
div#about-popup .skills .skill.p31::after {
  width: 31%;
}
div#about-popup .skills .skill.p32::after {
  width: 32%;
}
div#about-popup .skills .skill.p33::after {
  width: 33%;
}
div#about-popup .skills .skill.p34::after {
  width: 34%;
}
div#about-popup .skills .skill.p35::after {
  width: 35%;
}
div#about-popup .skills .skill.p36::after {
  width: 36%;
}
div#about-popup .skills .skill.p37::after {
  width: 37%;
}
div#about-popup .skills .skill.p38::after {
  width: 38%;
}
div#about-popup .skills .skill.p39::after {
  width: 39%;
}
div#about-popup .skills .skill.p40::after {
  width: 40%;
}
div#about-popup .skills .skill.p41::after {
  width: 41%;
}
div#about-popup .skills .skill.p42::after {
  width: 42%;
}
div#about-popup .skills .skill.p43::after {
  width: 43%;
}
div#about-popup .skills .skill.p44::after {
  width: 44%;
}
div#about-popup .skills .skill.p45::after {
  width: 45%;
}
div#about-popup .skills .skill.p46::after {
  width: 46%;
}
div#about-popup .skills .skill.p47::after {
  width: 47%;
}
div#about-popup .skills .skill.p48::after {
  width: 48%;
}
div#about-popup .skills .skill.p49::after {
  width: 49%;
}
div#about-popup .skills .skill.p50::after {
  width: 50%;
}
div#about-popup .skills .skill.p51::after {
  width: 51%;
}
div#about-popup .skills .skill.p52::after {
  width: 52%;
}
div#about-popup .skills .skill.p53::after {
  width: 53%;
}
div#about-popup .skills .skill.p54::after {
  width: 54%;
}
div#about-popup .skills .skill.p55::after {
  width: 55%;
}
div#about-popup .skills .skill.p56::after {
  width: 56%;
}
div#about-popup .skills .skill.p57::after {
  width: 57%;
}
div#about-popup .skills .skill.p58::after {
  width: 58%;
}
div#about-popup .skills .skill.p59::after {
  width: 59%;
}
div#about-popup .skills .skill.p60::after {
  width: 60%;
}
div#about-popup .skills .skill.p61::after {
  width: 61%;
}
div#about-popup .skills .skill.p62::after {
  width: 62%;
}
div#about-popup .skills .skill.p63::after {
  width: 63%;
}
div#about-popup .skills .skill.p64::after {
  width: 64%;
}
div#about-popup .skills .skill.p65::after {
  width: 65%;
}
div#about-popup .skills .skill.p66::after {
  width: 66%;
}
div#about-popup .skills .skill.p67::after {
  width: 67%;
}
div#about-popup .skills .skill.p68::after {
  width: 68%;
}
div#about-popup .skills .skill.p69::after {
  width: 69%;
}
div#about-popup .skills .skill.p70::after {
  width: 70%;
}
div#about-popup .skills .skill.p71::after {
  width: 71%;
}
div#about-popup .skills .skill.p72::after {
  width: 72%;
}
div#about-popup .skills .skill.p73::after {
  width: 73%;
}
div#about-popup .skills .skill.p74::after {
  width: 74%;
}
div#about-popup .skills .skill.p75::after {
  width: 75%;
}
div#about-popup .skills .skill.p76::after {
  width: 76%;
}
div#about-popup .skills .skill.p77::after {
  width: 77%;
}
div#about-popup .skills .skill.p78::after {
  width: 78%;
}
div#about-popup .skills .skill.p79::after {
  width: 79%;
}
div#about-popup .skills .skill.p80::after {
  width: 80%;
}
div#about-popup .skills .skill.p81::after {
  width: 81%;
}
div#about-popup .skills .skill.p82::after {
  width: 82%;
}
div#about-popup .skills .skill.p83::after {
  width: 83%;
}
div#about-popup .skills .skill.p84::after {
  width: 84%;
}
div#about-popup .skills .skill.p85::after {
  width: 85%;
}
div#about-popup .skills .skill.p86::after {
  width: 86%;
}
div#about-popup .skills .skill.p87::after {
  width: 87%;
}
div#about-popup .skills .skill.p88::after {
  width: 88%;
}
div#about-popup .skills .skill.p89::after {
  width: 89%;
}
div#about-popup .skills .skill.p90::after {
  width: 90%;
}
div#about-popup .skills .skill.p91::after {
  width: 91%;
}
div#about-popup .skills .skill.p92::after {
  width: 92%;
}
div#about-popup .skills .skill.p93::after {
  width: 93%;
}
div#about-popup .skills .skill.p94::after {
  width: 94%;
}
div#about-popup .skills .skill.p95::after {
  width: 95%;
}
div#about-popup .skills .skill.p96::after {
  width: 96%;
}
div#about-popup .skills .skill.p97::after {
  width: 97%;
}
div#about-popup .skills .skill.p98::after {
  width: 98%;
}
div#about-popup .skills .skill.p99::after {
  width: 99%;
}
div#about-popup .skills .skill.p100::after {
  width: 100%;
}

/*----------------------------------------*/
/*  05. Section Tilte CSS START
/*----------------------------------------*/
.section-title {
  text-align: center;
  color: var(--cf-theme-dark);
}
.section-title p {
  color: var(--cf-theme-primary);
  margin-bottom: 5px;
  font-size: var(--cf-fs-h4);
}
.section-title h2 {
  font-weight: var(--cf-fw-bold);
}
.section-title.left {
  text-align: left;
}

/*----------------------------------------*/
/*  06. Preloader CSS START
/*----------------------------------------*/
#preloader:after {
  left: auto;
  right: 0;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#preloader:after {
  left: auto;
  right: 0;
}

#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

.loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: white;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}

.loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--cf-theme-dark);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}

@-webkit-keyframes lineheight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes lineheight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
@-webkit-keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}
@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}
/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}

.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}

.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}

@-webkit-keyframes preloadedzero {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

@keyframes preloadedzero {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}
body.intro #preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

body.intro .loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--cf-theme-dark);
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}

body.intro .loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--cf-theme-dark);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}

body.intro #preloader:before,
body.intro #preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

body.intro #preloader:after {
  left: auto;
  right: 0;
}

body.intro #preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

/*----------------------------------------*/
/*  07. pogress CSS START
/*----------------------------------------*/
.progress {
  background: transparent;
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.progress::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: "\f176";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--cf-theme-dark);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress:hover::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: "\f176";
  color: var(--cf-theme-dark);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress svg path {
  fill: none;
}
.progress svg.progress-svg path {
  stroke: var(--cf-theme-dark);
  stroke-width: 4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/*----------------------------------------*/
/*  08. cursor CSS START
/*----------------------------------------*/
.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  margin-left: -12.5px;
  margin-top: -12.5px;
  width: 25px;
  height: 25px;
  opacity: 0.6;
  z-index: 999;
  background-color: var(--cf-theme-primary);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .cursor {
    display: none;
  }
}

.cursor.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: var(--cf-theme-primary);
  opacity: 0.3;
}

/*----------------------------------------*/
/*  09. header CSS START
/*----------------------------------------*/
header {
  z-index: 9;
}
header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  header .container {
    display: block;
    position: relative;
  }
}
header .container .menus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
}
header .container .menus li {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
header .container .menus li.current {
  color: var(--cf-theme-primary);
  position: relative;
}
header .container .menus li.current::after {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  content: "";
  background: var(--cf-theme-primary);
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  position: absolute;
  bottom: 7px;
  right: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  header .container .menus {
    display: none;
    margin-top: 20px;
  }
  header .container .menus li {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  header .container .social-icons {
    display: none;
  }
}
header .container .toggle {
  position: relative;
  width: 40px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  overflow: hidden;
  display: none;
  position: absolute;
  right: 15px;
  top: 0;
}
header .container .toggle span {
  position: absolute;
  width: 40px;
  height: 3px;
  background: var(--cf-theme-white);
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
}
header .container .toggle span:nth-child(1) {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  width: 25px;
}
header .container .toggle span:nth-child(2) {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  width: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  header .container .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
header.active {
  max-height: 100%;
  background: var(--cf-theme-dark);
  padding-bottom: 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
header.active .toggle span:nth-child(1) {
  width: 30px;
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  -webkit-transition-delay: 0.125s;
  transition-delay: 0.125s;
}
header.active .toggle span:nth-child(2) {
  width: 30px;
  -webkit-transform: translateY(0) rotate(315deg);
  transform: translateY(0) rotate(315deg);
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
header.active .toggle span:nth-child(3) {
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
}
header .header-container {
  padding: 30px 0;
}
header .header-container.fixed {
  position: fixed !important;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  -webkit-animation: sticky 1s;
          animation: sticky 1s;
  background: var(--cf-theme-dark);
}

/*----------------------------------------*/
/*  10. hero CSS START
/*----------------------------------------*/
#home {
  position: relative;
}
#home .col-lg-7.col-12 {
  z-index: 2;
  position: relative;
}
#home h1 {
  padding: 155px 0 0;
}
#home .short-bio {
  margin-top: 24px;
  margin-bottom: 40px;
}
#home .counter {
  margin-top: 80px;
  padding: 0 0 56px;
}
#home .counter p {
  font-size: 20px;
}
#home .counter h2 {
  font-size: var(--cf-fs-h2);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #home .counter h2 {
    font-size: 36px;
  }
}
#home .extra-bg {
  display: none;
  height: 100%;
  background: var(--cf-theme-primary);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70px;
  z-index: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #home .extra-bg {
    display: block;
  }
}
#home .hero-right {
  height: 100%;
  top: 0;
  right: 0px;
  position: absolute;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #home .hero-right {
    display: none;
  }
}
#home .hero-right-bg {
  height: 100%;
  background: var(--cf-theme-primary);
  width: 360px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #home .hero-right-bg {
    width: 120px;
  }
}
#home .hero-right img {
  position: absolute;
  bottom: 0;
  right: -60px;
  max-width: 500px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #home .hero-right img {
    display: none;
  }
}

/*----------------------------------------*/
/*  11. service CSS START
/*----------------------------------------*/
#services {
  margin-top: 120px;
}
#services .card-container {
  margin: 50px 0 180px;
}
#services .service-card {
  position: relative;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
          box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  border-radius: 20px;
  padding: 36px;
  margin-bottom: 30px;
  color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#services .service-card .icon-container {
  width: 72px;
}
#services .service-card .icon-container svg {
  color: var(--cf-theme-dark);
}
#services .service-card h3 {
  margin: 25px 0 17px;
}
#services .service-card:hover {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}
#services .service-card:hover p {
  color: var(--cf-theme-white);
}
#services .service-card:hover svg {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
#services .service-card a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

/*----------------------------------------*/
/*  12. about CSS START
/*----------------------------------------*/
#about {
  margin-bottom: 200px;
}
#about .section-title {
  margin-bottom: 25px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #about .section-title {
    margin-top: 60px;
  }
}
#about .cf-btn {
  padding: 15px 40px;
}
@media (max-width: 400px) {
  #about .cf-btn {
    padding: 15px 20px;
  }
}
@media (max-width: 350px) {
  #about .cf-btn {
    padding: 10px 14px;
    -webkit-box-flex: 70%;
        -ms-flex: 70%;
            flex: 70%;
    text-align: center;
  }
}
#about .watch-video {
  margin-left: 30px;
}
@media (max-width: 400px) {
  #about .watch-video {
    margin-left: 20px;
  }
}
#about .watch-video a {
  margin-right: 10px;
  color: var(--cf-theme-dark);
  font-size: 50px;
  display: contents;
}
#about .watch-video p {
  margin-bottom: 0;
  margin-left: 10px;
  color: var(--cf-theme-dark);
}
#about .btn-container {
  margin-top: 50px;
}
#about .author-image-container {
  position: relative;
  height: 100%;
}
#about .author-image-container .image-bg {
  position: absolute;
  width: 270px;
  height: 270px;
  background-color: var(--cf-theme-primary);
  bottom: -65px;
  left: -32px;
  z-index: 0;
  border-radius: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #about .author-image-container .image-bg {
    width: 200px;
    height: 200px;
    bottom: 183px;
    left: -47px;
  }
}
#about .author-image-container .image-area img {
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  top: 0;
}

/*----------------------------------------*/
/*  13. portfolio CSS START
/*----------------------------------------*/
#portfolio {
  margin-bottom: 120px;
}
#portfolio .portfolio-container .portfolio-items {
  width: 100%;
  margin: auto;
}
#portfolio .portfolio-container .filter-btn {
  text-align: center;
  margin: 24px 0 40px;
  color: var(--cf-theme-dark);
}
#portfolio .portfolio-container .filter-btn a {
  margin: 15px 40px 0 0;
  display: inline-block;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #portfolio .portfolio-container .filter-btn a {
    margin: 15px 20px 0 0;
  }
}
#portfolio .portfolio-container .filter-btn a.active {
  color: var(--cf-theme-primary);
}
#portfolio .portfolio-container .filter-btn a.active::after {
  content: "";
  background: var(--cf-theme-primary);
  height: 6px;
  width: 6px;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-block;
}
#portfolio .portfolio-container .item {
  overflow: hidden;
  margin: 10px;
  border-radius: 20px;
  width: calc(33% - 20px);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #portfolio .portfolio-container .item {
    width: calc(50% - 20px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  #portfolio .portfolio-container .item {
    width: calc(100% - 20px);
  }
}
#portfolio .portfolio-container .item a img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
}
#portfolio .portfolio-container .item a img:hover {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
          transform: scale3d(1.1, 1.1, 1.1);
}

/*----------------------------------------*/
/*  14. testimonials CSS START
/*----------------------------------------*/
#testimonials .testimonials-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#testimonials .testimonials-top .arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#testimonials .testimonials-top .arrow-left {
  margin-right: 10px;
}
#testimonials .testimonials-top .arrow {
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: #f2f2f2;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#testimonials .testimonials-top .arrow.active {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}
#testimonials .testimonials-top .arrow:hover {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}
#testimonials .testimonial-sliders {
  margin-top: 50px;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
          box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  border-radius: 20px;
}
#testimonials .slider-item {
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.07);
          box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.07);
}
#testimonials .slider-item .author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  margin-top: 40px;
}
#testimonials .slider-item .author-info img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
#testimonials .slider-item h3 {
  color: var(--cf-theme-dark);
}
#testimonials .slider-item .complay-name {
  font-size: 20px;
}
#testimonials .slider-item p {
  font-style: italic;
}
#testimonials .slider-item .slider-item-content {
  padding: 48px 36px 30px;
}

/*----------------------------------------*/
/*  15. client CSS START
/*----------------------------------------*/
#client {
  margin: 120px 0;
}
#client .client-container {
  border-radius: 20px;
  padding-top: 10px;
}
#client .client-container a {
  height: 100%;
}
#client .client-container img,
#client .client-container a {
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  max-width: 100%;
}
#client .client-container .swiper-slide-active img,
#client .client-container .swiper-slide:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

/*----------------------------------------*/
/*  16. blog CSS START
/*----------------------------------------*/
#blog {
  margin-bottom: 180px;
}
#blog .blog-items {
  margin-top: 50px;
}
#blog .blog-item {
  border-radius: 20px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
          box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
}
#blog .blog-item span {
  color: var(--cf-theme-primary);
}
#blog .blog-item .blog-image-container {
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
#blog .blog-item img {
  width: 100%;
  border-radius: 20px 20px 0 0;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
}
#blog .blog-item img:hover {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
          transform: scale3d(1.1, 1.1, 1.1);
}
#blog .blog-item .blog-content {
  padding: 30px;
}
#blog .blog-item .blog-content p {
  margin-bottom: 0;
}
#blog .blog-item .blog-content h4 {
  margin-top: 0;
}
#blog .blog-item h4 {
  color: var(--cf-theme-dark);
  margin: 10px 0;
}

/*----------------------------------------*/
/*  17. contact CSS START
/*----------------------------------------*/
#contact {
  margin-bottom: 120px;
}
#contact .info-details {
  margin-top: 36px;
}
#contact .info-details li {
  margin-bottom: 30px;
}
#contact .info-details li h4 {
  color: var(--cf-theme-dark);
}
#contact .contact-form {
  background: var(--cf-theme-primary);
  padding: 100px 50px 50px;
  border-radius: 20px;
}
#contact .contact-form input,
#contact .contact-form textarea {
  width: 100%;
  color: var(--cf-theme-white);
  font-weight: var(--cf-fw-light);
  font-family: var(--cf-ff-primary);
  font-size: 18px;
  border: 0;
  border-bottom: 1.5px solid #a6a6a6;
  background: transparent;
  padding: 15px 0;
  margin-bottom: 40px;
}
#contact .contact-form button {
  background: var(--cf-theme-dark);
  color: var(--cf-theme-white);
  font-weight: var(--cf-fw-regular);
  font-family: var(--cf-ff-primary);
  font-size: 18px;
  margin: 0 auto;
  display: block;
}

/*----------------------------------------*/
/*  18. footer CSS START
/*----------------------------------------*/
footer {
  background: var(--cf-theme-primary);
  padding: 37px 0;
  color: var(--cf-theme-white);
  text-align: center;
}
footer .copy-right-text {
  margin-top: 10px;
  font-weight: 400;
}/*# sourceMappingURL=main.css.map */