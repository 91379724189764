/* .bg-img{
    background-image: url('../../assets/bg-vid.jpg');
    background-repeat: no-repeat;
}
.team-vid .vid-img {
    position: relative;
    min-height: 520px;
    padding: 80px 0;
    margin-top: -260px;
}
.svg-play {
    width: 50px;
}

.team-vid .vid-img .vid {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
} */
/* .flex-vv{
    
} */
.bg-VideoWithTeam{
    background: #1E1F21;
    padding: 100px;
}


/* position: relative;
min-height: 520px;
padding: 80px 0;
margin-top: -260px; */
.img-vplay{

}
.d2-vc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.d1-vc{
    margin-top: -354px;
}
.img-vplay {
    position: relative;
    padding: 80px 0;
    /* margin-top: -260px; */
    width: 80%;
    opacity: 0.8;
    height: 100%;
    object-fit: cover;
}
.play-iconvc{
    position: absolute;
}



