.term-div1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.term-div2 {
  width: 90%;
}
.term-div3 > h1 {
    font-size: 25px;
    text-align: center;
    padding: 20px 0px;
}
.term-p{
    font-size: 17px;
    padding-bottom: 117px;
}